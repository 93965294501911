import { useCallback, useMemo, useRef, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  Divider,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

import { GET_ADMIN_BID_LIST_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import {
  ADMIN_BID_FREIGHT_TYPE_OPTION_LIST,
  ADMIN_BID_INCOTERMS_OPTION_LIST,
} from "@sellernote/_shared/src/constants/forwarding/adminBid";
import useTableHeadFilterUnstyled from "@sellernote/_shared/src/hooks/admin/useTableHeadFilterUnstyled";
import {
  FreightType,
  Incoterms,
} from "@sellernote/_shared/src/types/common/common";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";

import { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD } from "../../../../../../constants/forwarding/adminZIndexLevelRecord";

export default function useFreightAndIncotermsHeadFilter(
  bidFilterData: GET_ADMIN_BID_LIST_REQ
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const incotermsHistory = useMemo(() => {
    if (bidFilterData.incoterms) {
      return { incoterms: bidFilterData.incoterms };
    }
  }, [bidFilterData]);

  const freightTypeHistory = useMemo(() => {
    if (bidFilterData.freightTypeList) {
      return { freightTypeList: bidFilterData.freightTypeList };
    }
  }, [bidFilterData]);

  const [headFilterData, setHeadFilterData] = useState<Partial<{
    incoterms: Incoterms[];
    freightTypeList: FreightType[];
  }> | null>({ ...incotermsHistory, ...freightTypeHistory });

  const [isCanceled, setIsCanceled] = useState<boolean>(true);

  const open = Boolean(anchorEl);

  const id = open ? "simple-popper" : undefined;

  const anchorElRef = useRef<HTMLDivElement>(null);

  const {
    FilterPanel: IncotermsFilterPanel,
    draftFilter: incotermsFilterData,
    handleFilterReset: incotermsReset,
  } = useTableHeadFilterUnstyled({
    filterOptions: ADMIN_BID_INCOTERMS_OPTION_LIST,
  });

  const {
    FilterPanel: FreightTypeFilterPanel,
    draftFilter: freightTypeFilterData,
    handleFilterReset: freightTypeReset,
  } = useTableHeadFilterUnstyled({
    filterOptions: ADMIN_BID_FREIGHT_TYPE_OPTION_LIST,
  });

  const incotermsFilter = useMemo(() => {
    return { incoterms: incotermsFilterData as Incoterms[] };
  }, [incotermsFilterData]);

  const freightTypeFilter = useMemo(() => {
    return { freightTypeList: freightTypeFilterData as FreightType[] };
  }, [freightTypeFilterData]);

  const handleSomewhereClick = useCallback(() => {
    setAnchorEl(null);
    setIsCanceled(true);
  }, []);

  const handleSubmitFilter = useCallback(() => {
    if (!incotermsFilter && !freightTypeFilter) return setAnchorEl(null);

    if (incotermsFilter || freightTypeFilter) {
      setHeadFilterData({ ...incotermsFilter, ...freightTypeFilter });

      setIsCanceled(false);
      setAnchorEl(null);
    }
  }, [freightTypeFilter, incotermsFilter]);

  const handleFilterOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);

      if (isCanceled) {
        if (incotermsHistory) {
          incotermsReset(incotermsHistory?.incoterms);
        }
        if (freightTypeHistory) {
          freightTypeReset(freightTypeHistory?.freightTypeList);
        }
        return setIsCanceled(false);
      } else {
        incotermsFilter && incotermsReset(incotermsFilter?.incoterms);
        freightTypeFilter &&
          freightTypeReset(freightTypeFilter.freightTypeList);
        return setIsCanceled(false);
      }
    },

    [
      anchorEl,
      incotermsHistory,
      incotermsReset,
      freightTypeHistory,
      freightTypeReset,
      isCanceled,
      incotermsFilter,
      freightTypeFilter,
    ]
  );

  const filterTriggerPosition = (() => {
    const target = anchorElRef.current;
    if (!target) {
      return null;
    }

    return target.getClientRects()[0];
  })();

  const handleReset = useCallback(() => {
    incotermsReset([]);
    freightTypeReset([]);
    setHeadFilterData(null);
  }, [incotermsReset, freightTypeReset]);

  const handleInsideReset = useCallback(() => {
    incotermsReset([]);
    freightTypeReset([]);
  }, [incotermsReset, freightTypeReset]);

  const FreightAndIncotermsHeadFilter = useMemo(() => {
    const DoubleFilter = () => {
      return (
        <>
          <IconButton
            aria-owns={id}
            aria-describedby={id}
            aria-haspopup={true}
            type="button"
            onClick={handleFilterOpen}
            color={
              !isEmptyObjectOrArray(headFilterData?.incoterms || {}) ||
              !isEmptyObjectOrArray(headFilterData?.freightTypeList || {})
                ? "primary"
                : "inherit"
            }
          >
            <FilterAltIcon />
          </IconButton>

          <Dialog open={open}>
            <Popper
              key={id}
              placement="bottom-start"
              anchorEl={anchorEl}
              id={id}
              open={open}
              transition
              style={{
                zIndex: FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.tableHeaderFilter,
              }}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener
                  onClickAway={handleSomewhereClick}
                  disableReactTree
                >
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      sx={{
                        padding: 4,
                        width: "100%",
                        minWidth: "340px",
                        position: "absolute",
                        maxHeight: "300px",
                        overflow: "scroll",
                        top: (filterTriggerPosition?.top ?? 0) + 17,
                        left: (filterTriggerPosition?.left ?? 0) - 40,
                        right: "100%",
                        display: "flex",
                        zIndex:
                          FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.tableHeaderFilter,
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "white",
                          width: 340,
                          height: 50,
                          position: "fixed",
                          top: 300,
                          left: -40,
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 1,
                        }}
                      >
                        <IconButton onClick={() => handleInsideReset()}>
                          <Typography
                            variant="caption"
                            sx={{ display: "inline-flex" }}
                          >
                            <RestartAltIcon />
                            reset
                          </Typography>
                        </IconButton>
                        <Button variant="outlined" onClick={handleSubmitFilter}>
                          적용
                        </Button>
                      </Box>
                      <Grid>{FreightTypeFilterPanel}</Grid>

                      <Divider flexItem orientation="vertical">
                        AND/OR
                      </Divider>

                      <Grid>{IncotermsFilterPanel}</Grid>
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </Dialog>
        </>
      );
    };

    return <Box>{DoubleFilter()}</Box>;
  }, [
    id,
    open,
    filterTriggerPosition?.left,
    filterTriggerPosition?.top,
    handleFilterOpen,
    anchorEl,
    IncotermsFilterPanel,
    FreightTypeFilterPanel,
    handleSomewhereClick,
    headFilterData,
    handleSubmitFilter,
    handleInsideReset,
  ]);

  return {
    FreightAndIncotermsHeadFilter,
    headFilterData,
    handleReset,
  };
}
