import { Dispatch, SetStateAction, useCallback } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

import useSnackbar from "../../../../../../hooks/admin/useSnackbar";

const CheckIsPaymentModal = ({
  setShowCheckIsPaymentModal,
  showCheckIsPaymentModal,
  bidId,
  onTrelloDetailOpen,
  endAddress,
}: {
  setShowCheckIsPaymentModal: Dispatch<SetStateAction<boolean>>;
  showCheckIsPaymentModal: boolean;
  bidId: number;
  onTrelloDetailOpen: (bidId: number) => void;
  endAddress: string | null;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const queryClient = useQueryClient();

  const {
    mutate: confirmInland,
    ResponseHandler: ResponseHandlerOfConfirmInland,
  } = TRELLO_BID_QUERY.useConfirmInland(bidId);

  const handleInlandConfirmClick = useCallback(() => {
    confirmInland(
      {},
      {
        onSuccess: () => {
          //TODO: 특송의 경우 endAddress를 ""으로 진행하는 경우가 있어서 임시로 null로만 체크 TMS 개발 시 변경될 예정
          if (endAddress === null) {
            handleSnackbarOpen("종료로 이동했습니다.");
            setShowCheckIsPaymentModal(false);
            queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.all());
          } else {
            setShowCheckIsPaymentModal(false);
            handleSnackbarOpen("내륙운송으로 이동했습니다.");
            queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.all());
          }
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [
    confirmInland,
    endAddress,
    handleSnackbarOpen,
    queryClient,
    setShowCheckIsPaymentModal,
  ]);

  const handleModalCancel = useCallback(() => {
    setShowCheckIsPaymentModal(false);
  }, [setShowCheckIsPaymentModal]);

  // TODO: 현재는 수입운송에서만 사용하고 있어 import로 고정 차후 기능이 필요하면 분기 추가
  const handleMoveToTradingStatementPageClick = useCallback(() => {
    history.push(`/tradingStatement/warehouseReceipt/${bidId}/import`);
  }, [bidId, history]);

  const handleTrelloModalOpenClick = useCallback(() => {
    setShowCheckIsPaymentModal(false);
    onTrelloDetailOpen(bidId);
  }, [bidId, onTrelloDetailOpen, setShowCheckIsPaymentModal]);

  return (
    <Dialog open={showCheckIsPaymentModal} onClose={handleModalCancel}>
      <DialogTitle>
        본 케이스는 수입신고 수리후 반출신고 되었습니다.
        <br />
        창고료 등의 정산이 완료되었습니까 ?
      </DialogTitle>

      <DialogActions>
        <Button variant="outlined" onClick={handleTrelloModalOpenClick}>
          나중에 하기
        </Button>

        <Button
          color="secondary"
          variant="outlined"
          onClick={handleMoveToTradingStatementPageClick}
        >
          <div>거래명세서 이동</div>
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleInlandConfirmClick}
        >
          예
        </Button>
      </DialogActions>

      {ResponseHandlerOfConfirmInland}
    </Dialog>
  );
};

export default CheckIsPaymentModal;
