import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import CUSTOMS_ADMIN_TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import useSnackbar from "../../../../../../../hooks/admin/useSnackbar";

const RequiredDocumentModal = ({
  showRequiredDocumentModal,
  setShowRequiredDocumentModal,
  trelloDetail,
  hasCIPLAttachment,
  hasCOAttachment,
  hasBrnAttachment,
  hasCertificateAttachment,
  hasEtcAttachment,
}: {
  showRequiredDocumentModal: boolean;
  setShowRequiredDocumentModal: React.Dispatch<React.SetStateAction<boolean>>;
  trelloDetail: TrelloBidDetail;
  hasCIPLAttachment: boolean;
  hasCOAttachment: boolean;
  hasBrnAttachment: boolean;
  hasCertificateAttachment: boolean;
  hasEtcAttachment: boolean;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const [ciCheckValue, setCiCheckValue] = useState(!hasCIPLAttachment);
  const [coCheckValue, setCoCheckValue] = useState(
    trelloDetail.needFTACertificateAgency ? false : !hasCOAttachment
  );
  const [brnCheckValue, setBrnCheckValue] = useState(!hasBrnAttachment);
  const [ciGuideCheckValue, setCiGuideCheckValue] = useState(false);
  const [certificateCheckValue, setCertificateCheckValue] = useState(false);
  const [etcCheckValue, setEtcCheckValue] = useState(false);
  const [ciInputValue, setCiInputValue] = useState(
    "C/I(Commercial Invoice, 상업송장) 및 P/L(Packing List, 포장명세서)는 수출자(판매자)가 수입자(구매자)에게 제공하는 서류로 통관을 위해 쉽다에 전달해야 하는 자료입니다.​"
  );
  const [coInputValue, setCoInputValue] = useState(
    "C/O(Certificate of Origin, 원산지증명서): 상품의 원산지를 나타내는 문서로 해당 서류를 국내 세관에 제출 시, 관세율을 절감할 수 있습니다. 수출자(판매자)로부터 수령하여 업로드 요청 드립니다."
  );
  const [brnInputValue, setBrnInputValue] = useState(
    "통관 및 정산을 위해 귀사의 사업자등록증을 업로드 요청 드립니다."
  );
  const [certificateInputValue, setCertificateInputValue] = useState(
    "수입요건이 존재하는 상품의 경우, 수입통관 시 인증서류가 필요합니다. 해당 서류를 구비하여 업로드 요청드립니다. "
  );
  const [etcInputValue, setEtcInputValue] = useState(
    "수입통관 시 참조해야 하는 기타서류가 있다면 업로드 요청 드립니다. "
  );
  const [ciGuideInputValue, setCiGuideInputValue] = useState(
    "수출자(판매자)가 C/I&P/L를 제공하기 어려운 경우 첨부문서를 참고해서 직접 작성 및 업로드 요청 드립니다."
  );

  const {
    mutate: sendRequiredDocumentEmail,
    ResponseHandler: ResponseHandlerOfUseSendRequiredDocumentEmail,
  } = TRELLO_BID_QUERY.useSendRequiredDocumentEmail({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
      },
      customizeMessage: () => ({
        title: "필요서류 요청 이메일을 보냈습니다.",
      }),
    },
    bidId: trelloDetail.id,
  });

  const {
    mutate: sendCustomsRequiredDocumentEmail,
    ResponseHandler: ResponseHandlerOfSendCustomsRequiredDocumentEmail,
  } = CUSTOMS_ADMIN_TRELLO_BID_QUERY.useSendCustomsRequiredDocumentEmail(
    trelloDetail.id
  );

  const requestSendRequiredDocumentEmail = useCallback(() => {
    let documentDetail = [];
    if (ciGuideCheckValue) {
      documentDetail = [
        {
          documentName: "C/I & P/L",
          uploadFlag: !ciCheckValue,
          comment: ciCheckValue ? ciInputValue : undefined,
        },
        {
          documentName: "sampleGuide",
          uploadFlag: ciGuideCheckValue,
          comment: ciGuideCheckValue ? ciGuideInputValue : undefined,
        },
        {
          documentName: "C/O",
          uploadFlag: !coCheckValue,
          comment: coCheckValue ? coInputValue : undefined,
        },
        {
          documentName: "사업자등록증",
          uploadFlag: !brnCheckValue,
          comment: brnCheckValue ? brnInputValue : undefined,
        },
        {
          documentName: "인증서류",
          uploadFlag: !certificateCheckValue,
          comment: certificateCheckValue ? certificateInputValue : undefined,
        },
        {
          documentName: "기타서류",
          uploadFlag: !etcCheckValue,
          comment: etcCheckValue ? etcInputValue : undefined,
        },
      ];
    } else {
      documentDetail = [
        {
          documentName: "C/I & P/L",
          uploadFlag: !ciCheckValue,
          comment: ciCheckValue ? ciInputValue : undefined,
        },
        {
          documentName: "C/O",
          uploadFlag: !coCheckValue,
          comment: coCheckValue ? coInputValue : undefined,
        },
        {
          documentName: "사업자등록증",
          uploadFlag: !brnCheckValue,
          comment: brnCheckValue ? brnInputValue : undefined,
        },
        {
          documentName: "인증서류",
          uploadFlag: !certificateCheckValue,
          comment: certificateCheckValue ? certificateInputValue : undefined,
        },
        {
          documentName: "기타서류",
          uploadFlag: !etcCheckValue,
          comment: etcCheckValue ? etcInputValue : undefined,
        },
      ];
    }
    if (APP_NAME === "shipda-admin") {
      return sendRequiredDocumentEmail({ documentDetail });
    }
    return sendCustomsRequiredDocumentEmail(
      { documentDetail },
      {
        onSuccess: () => {
          handleSnackbarOpen("필요서류 요청 이메일을 보냈습니다.");
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [
    brnCheckValue,
    brnInputValue,
    certificateCheckValue,
    certificateInputValue,
    ciCheckValue,
    ciGuideCheckValue,
    ciGuideInputValue,
    ciInputValue,
    coCheckValue,
    coInputValue,
    etcCheckValue,
    etcInputValue,
    handleSnackbarOpen,
    sendCustomsRequiredDocumentEmail,
    sendRequiredDocumentEmail,
  ]);

  const rows = useMemo(
    () => [
      {
        type: "C/I & P/L",
        hasAttachment: hasCIPLAttachment,
        request: (
          <Checkbox
            defaultChecked={ciCheckValue}
            onChange={(e) => setCiCheckValue(e.target.checked)}
          ></Checkbox>
        ),
        comment: ciCheckValue && (
          <TextField
            value={ciInputValue}
            size="small"
            fullWidth
            multiline
            onChange={(event) => {
              setCiInputValue(event.target.value);
            }}
          />
        ),
      },
      {
        type: "C/I & P/L 가이드",
        hasAttachment: undefined,
        request: (
          <Checkbox
            defaultChecked={ciGuideCheckValue}
            onChange={(e) => setCiGuideCheckValue(e.target.checked)}
          ></Checkbox>
        ),
        comment: ciGuideCheckValue && (
          <TextField
            value={ciGuideInputValue}
            size="small"
            fullWidth
            multiline
            onChange={(event) => {
              setCiGuideInputValue(event.target.value);
            }}
          />
        ),
      },
      {
        type: "C/O",
        hasAttachment: hasCOAttachment,
        request: (
          <Checkbox
            defaultChecked={coCheckValue}
            onChange={(e) => setCoCheckValue(e.target.checked)}
          ></Checkbox>
        ),
        comment: coCheckValue && (
          <TextField
            value={coInputValue}
            size="small"
            fullWidth
            multiline
            onChange={(event) => {
              setCoInputValue(event.target.value);
            }}
          />
        ),
      },
      {
        type: "사업자등록증",
        hasAttachment: hasBrnAttachment,
        request: (
          <Checkbox
            defaultChecked={brnCheckValue}
            onChange={(e) => setBrnCheckValue(e.target.checked)}
          ></Checkbox>
        ),
        comment: brnCheckValue && (
          <TextField
            value={brnInputValue}
            size="small"
            fullWidth
            multiline
            onChange={(event) => {
              setBrnInputValue(event.target.value);
            }}
          />
        ),
      },
      {
        type: "인증서류",
        hasAttachment: hasCertificateAttachment,
        request: (
          <Checkbox
            defaultChecked={certificateCheckValue}
            onChange={(e) => setCertificateCheckValue(e.target.checked)}
          ></Checkbox>
        ),
        comment: certificateCheckValue && (
          <TextField
            value={certificateInputValue}
            size="small"
            fullWidth
            multiline
            onChange={(event) => {
              setCertificateInputValue(event.target.value);
            }}
          />
        ),
      },
      {
        type: "기타서류",
        hasAttachment: hasEtcAttachment,
        request: (
          <Checkbox
            defaultChecked={etcCheckValue}
            onChange={(e) => setEtcCheckValue(e.target.checked)}
          ></Checkbox>
        ),
        comment: etcCheckValue && (
          <TextField
            value={etcInputValue}
            size="small"
            fullWidth
            multiline
            onChange={(event) => {
              setEtcInputValue(event.target.value);
            }}
          />
        ),
      },
    ],
    [
      brnCheckValue,
      brnInputValue,
      certificateCheckValue,
      certificateInputValue,
      ciCheckValue,
      ciGuideCheckValue,
      ciGuideInputValue,
      ciInputValue,
      coCheckValue,
      coInputValue,
      etcCheckValue,
      etcInputValue,
      hasBrnAttachment,
      hasCIPLAttachment,
      hasCOAttachment,
      hasCertificateAttachment,
      hasEtcAttachment,
    ]
  );

  return (
    <Dialog
      open={showRequiredDocumentModal}
      onClose={() => setShowRequiredDocumentModal(false)}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>필요서류 요청하기</DialogTitle>

      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>구분</TableCell>
                <TableCell>파일 업로드 여부</TableCell>
                <TableCell>서류 요청</TableCell>
                <TableCell>코멘트</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(({ type, hasAttachment, request, comment }) => (
                <TableRow key={type}>
                  <TableCell sx={{ fontWeight: 600 }}>{type}</TableCell>
                  <TableCell>
                    {hasAttachment !== undefined
                      ? hasAttachment
                        ? "Y"
                        : "N"
                      : ""}
                  </TableCell>
                  <TableCell>{request}</TableCell>
                  <TableCell>{comment}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !ciCheckValue &&
            !coCheckValue &&
            !brnCheckValue &&
            !certificateCheckValue &&
            !etcCheckValue
          }
          onClick={requestSendRequiredDocumentEmail}
        >
          요청하기
        </Button>
      </DialogActions>

      {ResponseHandlerOfUseSendRequiredDocumentEmail}
      {ResponseHandlerOfSendCustomsRequiredDocumentEmail}
    </Dialog>
  );
};

export default RequiredDocumentModal;
