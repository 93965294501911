import React, { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import ADMIN_SLACK_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_SLACK_QUERY";

const RejectSettlementModal = ({
  setShowRejectSettlementModal,
  showRejectSettlementModal,
  bidId,
}: {
  setShowRejectSettlementModal: React.Dispatch<React.SetStateAction<boolean>>;
  showRejectSettlementModal: boolean;
  bidId: number;
}) => {
  const [rejectReason, setRejectReason] = useState("");

  const {
    mutate: rejectSettlement,
    ResponseHandler: ResponseHandlerOfUseRejectSettlement,
  } = ADMIN_SLACK_QUERY.useRejectSettlement({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setShowRejectSettlementModal(false);
      },
      customizeMessage: () => ({
        title: "정산반려 슬랙 알람을 발송했습니다.",
      }),
    },
  });

  const handleModalClose = useCallback(() => {
    setShowRejectSettlementModal(false);
  }, [setShowRejectSettlementModal]);

  const handleRejectSettlementClick = useCallback(() => {
    rejectSettlement({
      bidId,
      rejectReason: !rejectReason ? "사유 미기재" : rejectReason,
    });
  }, [bidId, rejectReason, rejectSettlement]);

  const handleRejectReasonInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRejectReason(e.target.value);
    },
    []
  );

  return (
    <Dialog open={showRejectSettlementModal} onClose={handleModalClose}>
      <DialogTitle>정산반려 요청 사유입력</DialogTitle>

      <DialogContent>
        <TextField
          placeholder="반려 요청 사유를 입력하세요"
          value={rejectReason}
          onChange={handleRejectReasonInputChange}
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!rejectReason}
          onClick={handleRejectSettlementClick}
        >
          요청하기
        </Button>
      </DialogActions>

      {ResponseHandlerOfUseRejectSettlement}
    </Dialog>
  );
};

export default RejectSettlementModal;
