import { useCallback, useEffect } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useSetRecoilState } from "recoil";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import SHIP_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/SHIP_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import {
  ExportTrelloList,
  FetchTrelloListParams,
} from "@sellernote/_shared/src/types/forwarding/trello";

import useSetDebuggingDataForIssueReport from "../../../../../../hooks/admin/setDebuggingDataForIssueReport";

import ShipmentCommentList from "../../../TrelloDetail/components/ShipmentCommentList";

import { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD } from "../../../../../../constants/forwarding/adminZIndexLevelRecord";
import BasicInfo from "../../../_components/detail/BasicInfo";
import ContainerInfo from "../../../_components/detail/ContainerInfo";
import Profit from "../../../_components/detail/Profit";
import PurchaseManagement from "../../../_components/detail/PurchaseManagement";
import SalesManagement from "../../../_components/detail/SalesManagement";
import ShipmentSchedule from "../../../_components/detail/ShipmentSchedule";
import ShipNameManagement from "../../../_components/detail/ShipNameManagement";
import UploadDocuments from "../../../_components/detail/UploadDocuments";
import useRefreshTrelloListForDetailModal from "../../../_hooks/useRefreshTrelloListForDetailModal";

function DetailModal({
  showsExportTrelloDetailModal,
  setShowsExportTrelloDetailModal,
  bidId,
  trelloListData,
  fetchTrelloListParams,
}: {
  showsExportTrelloDetailModal: boolean;
  setShowsExportTrelloDetailModal: (value: boolean) => void;
  bidId: number | undefined;
  trelloListData?: ExportTrelloList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) {
  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const { data: trelloDetailData } = TRELLO_BID_QUERY.useGetExportTrelloDetail({
    bidId,
  });

  const { data: shipList } = SHIP_QUERY.useGetShipList();

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: trelloDetailData?.locale,
  });

  const { partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: 0,
    enabled: true,
    pageFlag: false,
    region: trelloDetailData?.locale ?? "KR",
  });

  const { refreshTrelloListForDetailModal } =
    useRefreshTrelloListForDetailModal({
      trelloListData,
      fetchTrelloListParams,
      isImport: false,
      trelloDetailData,
    });

  const handleCloseModal = useCallback(() => {
    setShowsExportTrelloDetailModal(false);

    refreshTrelloListForDetailModal();
  }, [refreshTrelloListForDetailModal, setShowsExportTrelloDetailModal]);

  const isFCLOrLCL =
    trelloDetailData?.freightType === "FCL" ||
    trelloDetailData?.freightType === "LCL";

  const { setDebuggingDataForIssueReport } =
    useSetDebuggingDataForIssueReport();

  if (trelloDetailData) {
    setDebuggingDataForIssueReport({
      bidId: trelloDetailData.id.toString(),
      userId: trelloDetailData.userId.toString(),
      userCompany: trelloDetailData.user.company,
    });
  }

  useEffect(() => {
    setTrelloCardId(undefined);
  }, [setTrelloCardId]);

  return (
    <Dialog
      open={showsExportTrelloDetailModal}
      maxWidth={false}
      fullWidth
      disableEnforceFocus
      onClose={handleCloseModal}
      sx={{
        zIndex: FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.drawer - 1,
      }}
    >
      <DialogContent sx={{ height: "100vh" }}>
        <IconButton
          size="large"
          sx={{ position: "absolute", top: 0, right: 0 }}
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>

        {!trelloDetailData || !shipList || !exchangeRateList || !partnerList ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <BasicInfo
              trelloDetail={trelloDetailData}
              partnerList={partnerList}
            />

            <ShipNameManagement trelloDetail={trelloDetailData} />

            {isFCLOrLCL && <ContainerInfo shipmentId={trelloDetailData.id} />}

            <ShipmentSchedule trelloDetail={trelloDetailData} />

            <UploadDocuments trelloDetail={trelloDetailData} />

            <SalesManagement
              trelloDetailData={trelloDetailData}
              isExport={true}
            />

            <PurchaseManagement
              trelloDetailData={trelloDetailData}
              partnerList={partnerList}
              exchangeRateList={exchangeRateList}
            />

            <Profit trelloDetail={trelloDetailData} />

            <ShipmentCommentList trelloDetailData={trelloDetailData} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default DetailModal;
